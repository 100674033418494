import React from 'react';
import * as Styled from './Solution.styled';
import { useTranslation } from 'react-i18next';
import Img from 'static/images/solution.png';

export const Solution = () => {
    const { t } = useTranslation();
    return (
        <Styled.Wrap>
            <Styled.ColLeft>
                <Styled.Heading>{t('solution.heading')}</Styled.Heading>
                <Styled.Description>
                    {t('solution.description')}
                </Styled.Description>
            </Styled.ColLeft>
            <Styled.ColRight>
                <Styled.Image src={Img} alt={t('solution.imgAlt')} />
            </Styled.ColRight>
        </Styled.Wrap>
    );
};
