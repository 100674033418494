import React, { ReactElement } from 'react';
import * as Styled from './TechnicalSolution.styled';
import { useTranslation } from 'react-i18next';
import { OrderedList } from '../shared/OrderedList';
import { UnorderedList } from '@/components/shared/UnorderedList';

interface TechnicalSolutionProps {
    title: string;
    subtitle?: string;
    items: string[];
    img: string;
    imgAlt: string;
    unorderedList?: boolean;
    listAlt?: boolean;
}

export function TechnicalSolution({
    title,
    subtitle,
    items,
    img,
    imgAlt,
    unorderedList,
    listAlt,
}: TechnicalSolutionProps): ReactElement {
    const { t } = useTranslation();
    const listItems = items.map((item) => (
        <Styled.ListItem dangerouslySetInnerHTML={{ __html: item }} />
    ));
    return (
        <Styled.Wrap>
            <Styled.Row>
                <Styled.ColLeft>
                    <Styled.Heading>{title}</Styled.Heading>
                    <Styled.SubHeading>{subtitle}</Styled.SubHeading>
                    {unorderedList ? (
                        <UnorderedList>{listItems}</UnorderedList>
                    ) : (
                        <OrderedList isAlt={listAlt}>{listItems}</OrderedList>
                    )}
                </Styled.ColLeft>
                <Styled.ColRight>
                    <Styled.Image src={img} alt={imgAlt} />
                </Styled.ColRight>
            </Styled.Row>
        </Styled.Wrap>
    );
}
