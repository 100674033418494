import tw from 'twin.macro';
import styled from 'styled-components';

export const Heading3 = tw.h3`text-3xl text-left mb-10 md:mb-15 leading-normal`;

export const Wrap = tw.div`mb-12 lg:mb-24 relative`;

export const Heading4 = tw.h4`text-lg md:text-xl text-left mt-4 mb-6 md:mb-8 md:mt-0`;
export const Heading4Alt = tw.h4`text-lg md:text-xl text-left text-white mt-4 mb-6 md:mb-8 md:mt-0`;

export const Description = tw.li`text-sm text-left font-normal font-display mb-12`;
export const DescriptionAlt = tw.li`text-sm text-left text-white font-normal font-display mb-12`;

export const BoxContainer = tw.div`flex flex-col md:flex-row`;
export const LeftBox = tw.div`relative py-8 px-6 md:px-10 md:pt-12 md:pb-32 border-3 border-primaryPurple rounded-xl bg-customLightGrey md:mr-2 md:w-1/2 mb-10 md:mb-0`;
export const RightBox = tw.div`relative py-8 px-6 md:px-10 md:pt-12 md:pb-32 bg-primaryPurple rounded-xl md:ml-2 md:w-1/2 `;

export const Icon = tw.img`absolute top-0 right-10 md:top-auto md:bottom-30 md:right-30 transform scale-50 md:scale-100 md:right-30 md:bottom-30`;

export const Image = styled.img`
    ${tw`absolute hidden md:block -z-10`};
    top: 450px;
    right: -110px;
    height: 520px;
`;
