import React, { useEffect, useState } from 'react';
import { AboutUs } from '@/components/AboutUs';
import { Hero } from '@/components/Hero';
import { ProblemWeSolve } from '@/components/ProblemWeSolve';
import { CurrentProcess } from '@/components/CurrentProcess';
import { Solution } from '@/components/Solution';
import { TechnicalSolution } from '@/components/TechnicalSolution';
import { Benefits } from '@/components/Benefits';
import { Features } from '@/components/Features';
import { ContactForm } from '@/components/ContactForm';
import Seo from '@/components/seo.js';
import { usePageContext } from '@/contexts/PageContext';
import { useWindowScroll } from 'react-use';
import EFRELogo from 'static/images/EFRE_EU_logo.jpg';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import i18n from '@/i18n/i18n';
import ModalVideo from 'react-modal-video';
import Img from './../../static/images/heroImg.png';
import { ImageWrap, Image } from '@/components/Hero/Hero.styled';
import { Ecosystem } from '@/components/Ecosystem';
import ProblemWeSolveImgPl from 'static/images/problems-we-solve-pl.png';
import ProblemWeSolveImgEn from 'static/images/problems-we-solve-en.png';
import TechnicalImg from 'static/images/technical-solution.png';

const ForHospitalsPage = ({ path }) => {
    const { setActiveNavItem } = usePageContext();
    const scrollRef1 = React.useRef(null);
    const scrollRef2 = React.useRef(null);
    const scrollRef3 = React.useRef(null);
    const scrollRef4 = React.useRef(null);
    const scrollRef5 = React.useRef(null);
    const { y } = useWindowScroll();
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState(false);
    const isGerman = i18n.language === 'de';
    const isPolish = i18n.language === 'pl';
    const videoUrl = isGerman ? 'uyHIBWlUFt4' : 'z_aC597ln3U';
    const ProblemWeSolveImg = isPolish
        ? ProblemWeSolveImgPl
        : ProblemWeSolveImgEn;

    useEffect(() => {
        if (scrollRef1.current) {
            const yy = y + 200;
            yy < scrollRef2.current.offsetTop && setActiveNavItem('home');
            yy < scrollRef3.current.offsetTop &&
                yy > scrollRef2.current.offsetTop &&
                setActiveNavItem('problem');
            yy < scrollRef4.current.offsetTop &&
                yy > scrollRef3.current.offsetTop &&
                setActiveNavItem('solution');
            yy < scrollRef5.current.offsetTop &&
                yy > scrollRef4.current.offsetTop &&
                setActiveNavItem('aboutUs');
            yy > scrollRef5.current.offsetTop && setActiveNavItem('contact');
        }
    }, [y, setActiveNavItem]);

    const videoOptions = {
        autoplay: 1,
        cc_load_policy: 1,
        color: null,
        controls: 1,
        disablekb: 0,
        enablejsapi: 1,
        end: null,
        fs: 1,
        h1: null,
        iv_load_policy: 1,
        list: null,
        listType: null,
        loop: 0,
        modestbranding: null,
        origin: null,
        playlist: null,
        playsinline: null,
        rel: 0,
        showinfo: 1,
        start: 0,
        wmode: 'transparent',
        theme: 'dark',
        mute: 0
    }

    return (
        <>
            <Seo title="Medrefer" />
            <section id="hero" ref={scrollRef1}>
                <Hero
                    title={t('hospitals.heroTitle')}
                    image={
                        <>
                            <motion.div>
                                <ImageWrap onClick={() => setOpen(true)}>
                                    <Image src={Img} alt={t('hero.imgAlt')} />
                                </ImageWrap>
                            </motion.div>
                            <ModalVideo
                                style={{ zIndex: 99 }}
                                channel="youtube"
                                youtube={videoOptions}
                                isOpen={isOpen}
                                videoId={videoUrl}
                                onClose={() => setOpen(false)}
                            />
                        </>
                    }
                    activeButton="for-hospitals"
                />
            </section>
            <section id="problem" ref={scrollRef2}>
                <ProblemWeSolve image={ProblemWeSolveImg} />
                <CurrentProcess
                    title={t('currentProcess.heading')}
                    items={[
                        {
                            subheading: t('currentProcess.subheading1'),
                            description: t('currentProcess.description1'),
                        },
                        {
                            subheading: t('currentProcess.subheading2'),
                            description: t('currentProcess.description2'),
                        },
                        {
                            subheading: t('currentProcess.subheading3'),
                            description: t('currentProcess.description3'),
                        },
                        {
                            subheading: t('currentProcess.subheading4'),
                            description: t('currentProcess.description4'),
                        },
                        {
                            subheading: t('currentProcess.subheading5'),
                            description: t('currentProcess.description5'),
                        },
                    ]}
                />
                <TechnicalSolution
                    title={t('technicalSolution.heading')}
                    items={[
                        t('technicalSolution.item1'),
                        t('technicalSolution.item2'),
                        t('technicalSolution.item3'),
                        t('technicalSolution.item4'),
                    ]}
                    img={TechnicalImg}
                    imgAlt={t('technicalSolution.imgAlt')}
                />
            </section>
            <section id="solution" ref={scrollRef3}>
                <Solution />
                <Ecosystem
                    title={t('ecosystem.heading')}
                    subtitle={t('ecosystem.subheading')}
                    items={[
                        t('ecosystem.item1'),
                        t('ecosystem.item2'),
                        t('ecosystem.item3'),
                    ]}
                />
                <Benefits />
                <Features />
            </section>
            <section id="aboutUs" ref={scrollRef4}>
                <AboutUs />
            </section>
            <section id="contact" ref={scrollRef5}>
                <ContactForm />
            </section>
            <section id="euro-logo">
                <div className="efre-logo-container">
                    <img src={EFRELogo} alt="EFRE EU Logo" />
                </div>
            </section>
        </>
    );
};

export default ForHospitalsPage;
