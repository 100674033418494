import React from 'react';
import * as Styled from './Benefits.styled';
import { useTranslation } from 'react-i18next';
import { UnorderedList } from '@/components/shared/UnorderedList';
import IconLeft from 'static/icons/graph-up-1.svg';
import IconRight from 'static/icons/euro.svg';
import DecorationDots from 'static/icons/dots1.svg';

export const Benefits = () => {
    const { t } = useTranslation();
    return (
        <Styled.Wrap>
            <Styled.Heading3>{t('benefits.heading')}</Styled.Heading3>
            <Styled.BoxContainer>
                <Styled.LeftBox>
                    <Styled.Heading4>
                        {t('benefits.subheadingLeft')}
                    </Styled.Heading4>
                    <UnorderedList>
                        <Styled.Description>
                            {t('benefits.itemLeft1')}
                        </Styled.Description>
                        <Styled.Description>
                            {t('benefits.itemLeft2')}
                        </Styled.Description>
                        <Styled.Description>
                            {t('benefits.itemLeft3')}
                        </Styled.Description>
                        <Styled.Description>
                            {t('benefits.itemLeft4')}
                        </Styled.Description>
                    </UnorderedList>
                    <Styled.Icon src={IconLeft} />
                </Styled.LeftBox>
                <Styled.RightBox>
                    <Styled.Heading4Alt>
                        {t('benefits.subheadingRight')}
                    </Styled.Heading4Alt>
                    <UnorderedList isAlt>
                        <Styled.DescriptionAlt>
                            {t('benefits.itemRight1')}
                        </Styled.DescriptionAlt>
                        <Styled.DescriptionAlt>
                            {t('benefits.itemRight2')}
                        </Styled.DescriptionAlt>
                        <Styled.DescriptionAlt>
                            {t('benefits.itemRight3')}
                        </Styled.DescriptionAlt>
                        <Styled.DescriptionAlt>
                            {t('benefits.itemRight4')}
                        </Styled.DescriptionAlt>
                    </UnorderedList>
                    <Styled.Icon src={IconRight} />
                </Styled.RightBox>
            </Styled.BoxContainer>
            <Styled.Image src={DecorationDots} alt="" />
        </Styled.Wrap>
    );
};
