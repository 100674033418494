import tw from 'twin.macro';

export const Heading = tw.h3`text-3xl text-left mb-10 md:mb-15 leading-tight md:leading-normal`;

export const Description = tw.p`text-left mb-15 font-display text-base`;

export const Wrap = tw.div`flex flex-col md:flex-row mb-12 md:mb-32`;

export const ColLeft = tw.div`flex flex-col md:w-1/2 lg:pr-48`;
export const ColRight = tw.div`flex flex-col md:w-1/2`;

export const Image = tw.img``;
