import tw from 'twin.macro';
import styled from 'styled-components';

export const Heading = tw.h3`text-3xl text-left mb-8 leading-tight md:leading-normal`;

export const SubHeading = tw.span`text-base font-light mb-6`;

export const Wrap = tw.div`mb-12 md:mb-24`;

export const Row = tw.div`flex flex-col md:flex-row`;
export const ColLeft = tw.div`flex flex-col md:w-1/2 font-display font-light md:justify-center lg:pr-32`;
export const ColRight = tw.div`flex flex-col justify-center md:w-1/2`;

export const Image = tw.img``;

export const ListItem = styled.li`
    ${tw`text-sm text-left font-normal font-display mb-10 leading-tight`}

    img {
        position: absolute;
        right: -35px;
        top: -10px;
    }
`;
